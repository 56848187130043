import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Helmet } from "react-helmet";

// Utility function to strip HTML tags
const stripHtmlTags = (html) => {
	if (typeof window !== "undefined") {
		const temporalDivElement = document.createElement("div");
		temporalDivElement.innerHTML = html;
		return temporalDivElement.textContent || temporalDivElement.innerText || "";
	} else {
		return html.replace(/<\/?[^>]+(>|$)/g, ""); // Fallback for server-side rendering
	}
};

const FAQSchemaMarkup = () => {
	const data = useStaticQuery(graphql`
		query MyQuery {
			allWpFaq {
				nodes {
					faqFields {
						faqFields {
							question
							answer
						}
					}
				}
			}
		}
	`);

	const faqs = data.allWpFaq.nodes.map((node) => node.faqFields.faqFields);

	const faqSchema = {
		"@context": "https://schema.org",
		"@type": "FAQPage",
		mainEntity: faqs.map((faq) => ({
			"@type": "Question",
			name: faq.question,
			acceptedAnswer: {
				"@type": "Answer",
				// Strip HTML tags from the answer text
				text: stripHtmlTags(faq.answer),
			},
		})),
	};

	return (
		<Helmet>
			<script type="application/ld+json">{JSON.stringify(faqSchema)}</script>
		</Helmet>
	);
};

export default FAQSchemaMarkup;
